@import url('./css/calc.css');

/* Global Rules */
.d-none {
  display: none !important;
}

/* Navbr */
nav .menu-icons {
  font-size: 23px;
  display: none;
}

nav ul li a {
  margin-right: 10px;
  text-decoration: none;
}

nav ul li a.active {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  nav .menu-icons {
    display: block;
  }
}
